import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  personalizedNotEnoughError: false,
  notEnoughError: false,
  destUserUid: null,
  user: null,
};

const purchaseSlice = createSlice({
  name: "profile/purchase",
  initialState,
  reducers: {
    toggleNotEnoughError: (state, { payload }) => {
      state.notEnoughError = payload?.show;
      state.destUserUid = payload?.show && payload?.uid ? +payload?.uid : null;
    },
    togglePersonalizedNotEnoughError: (state, { payload }) => {
      state.personalizedNotEnoughError = payload?.show;
      state.user = payload?.show && payload?.user ? payload?.user : null;
    },
  },
});

export const { toggleNotEnoughError, togglePersonalizedNotEnoughError } =
  purchaseSlice.actions;

export default purchaseSlice.reducer;
